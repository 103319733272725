<template>
  <div v-if="detail">
    <div class="pc container-top">
      <div class="container">
        <div class="flex" style="padding:60px 0 52px 0">
          <div class="col-8" style="padding-right:30px">
            <launch-talk :vLogo="false"
                         :enableChat="detail.status<4"
                         :talkId="detail.talk" :talks.sync="talks"></launch-talk>
            <div v-if="detail.status === 4"
              class="box-done">수정작업이 완료되었습니다. 신규 요청이 있다면 내역에서 신청해주세요.</div>
          </div>
          <div class="col-4" style="padding-left:30px">
            <div class="box-estimate" v-if="detail.status >= 1">
              <div class="detail-title flex-between" style="margin-bottom:0">
                <div>견적서</div>
                <div class="body4" :class="`${detail.status === 1 ? 'primary' : 'sub3'}`">
                  {{ detail.status === 1 ? '결제대기' : '결제완료' }}</div>
              </div>
              <div class="grid-est">
                <div class="subtitle7 sub3">추가기능</div>
                <div class="body4 main text-right">{{ detail.estimate.feature }}</div>

                <div class="subtitle7 sub3">작업범위</div>
                <div class="body4 main text-right">{{ detail.estimate.arrange }}</div>

                <div class="subtitle7 sub3">작업기간</div>
                <div class="body4 main text-right">{{ detail.estimate.period }}</div>

                <div class="subtitle7 sub3">견적금액</div>
                <div class="body4 main text-right"><span class="body4-bold">{{ detail.price|currencyNum }}</span>원</div>
              </div>
              <div class="lp-divider-gray2"></div>
              <button v-if="detail.status === 1"
                      class="button is-primary button-est body2-medium" @click="clickAccount">결제하기</button>
              <button v-else
                class="button is-gray button-est body2-medium" @click="clickAccountList">결제내역 보기</button>
            </div>
            <div class="service-info">
              <div class="detail-title">서비스 정보</div>
              <div style="margin-bottom:80px">
                <div class="subtitle7 sub3">문의 유형</div>
                <div class="flex-align" style="gap:4px">
                  <div class="body4 main">{{ detail.category }}</div>
                  <div :class="`status skin-status-${detail.status}`">{{ detail.status_kor }}</div>
                </div>
                <div class="subtitle7 sub3">상품종류</div>
                <div class="body4 main">플랫폼파이 스킨 ({{ detail.product.name }})</div>

                <div class="subtitle7 sub3">요청사항</div>
                <div class="body4 main">{{ detail.content }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-center">
          <button class="button is-gray body2-medium" @click="clickService"
                  style="width:242px;height:48px">내 서비스 목록</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import LaunchTalk from "../component/LaunchTalk";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  export default {
    name: "SkinInquiryDetail",
    components: {LaunchTalk},
    mixins: [
      UserAPIMixin
    ],
    created() {
      this.getData();
    },
    data() {
      return {
        detail: undefined,
        talks: []
      }
    },
    methods: {
      clickService() {
        this.routerPush('/mypage/my_service');
      },
      getData() {
        this.request.user.get(`launchpack/v1/skin_inquiry/${this.$route.query.id}`).then(res => {
          this.detail = res.data;
        });
      },
      clickAccount() {
        this.$store.commit('setBasket', this.detail);
        this.routerPush('/skin_inquiry_account');
      },
      clickAccountList() {
        window.open(`${this.detail.service.domain}/office/admin/service/plan`,'_blank');
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc
    white-space pre-line
    word-break break-word
    .detail-title
      color $main
      font-size 24px
      line-height 34px
      font-weight 500
      padding-bottom 16px
      border-bottom 1px solid $sub5
      margin-bottom 32px
    .service-info
      .subtitle7
        margin 24px 0 4px 0
      .subtitle7:first-child
        margin-top 0

    .box-estimate
      border 1px solid $gray1
      border-radius 12px
      padding 32px
      margin-bottom 40px
    .grid-est
      display grid
      grid-template-columns 80px 1fr
      grid-row-gap 10px
      margin 16px 0

    .lp-divider-gray2
      width 100%
      height 1px
      background-color $gray2
      margin-bottom 16px
    .button-est
      width 100%
      height 48px

    .box-done
      border 1px solid $gray1
      padding 12px 16px
      border-radius 8px
      background-color $gray4
      font-size 15px
      color $sub4

  .skin-status-0
    background-color $gray2
    color $sub2
  .skin-status-1
    background-color $error50
    color $error
  .skin-status-2
  .skin-status-3
  .skin-status-4
    background-color $subcolor50
    color $subcolor2
</style>
